var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-navigation-drawer',{attrs:{"id":"sidebar-bg","app":"","permanent":_vm.isPermanent,"mini-variant":_vm.isMini,"mini-variant-width":"95","width":"280"},on:{"update:miniVariant":function($event){_vm.isMini=$event},"update:mini-variant":function($event){_vm.isMini=$event}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticClass:"mx-3 pb-16",attrs:{"dense":""}},[_c('v-list-item',{staticClass:"mt-3 mb-6"},[_c('img',{staticClass:"sidebar-menu-logo-icon",attrs:{"src":require('@/assets/images/sidebar-icon/timebinder-icon-menu.svg'),"height":"20px","alt":"sidebar-menu-logo"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('img',{staticClass:"ml-3",attrs:{"src":require('@/assets/images/sidebar-icon/timebinder-icon-menu-logo.svg'),"height":"24px","alt":"timebinder-logo"}})]),_vm._l((_vm.pages),function(page){return _c('div',{key:page.displayName,attrs:{"data-cy":"navbar"}},[(page.viewPermission)?_c('div',[(page.children)?[_c('v-list-group',{staticClass:"title-nav",attrs:{"no-action":"","value":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-action',{staticClass:"parent-title-icon",class:{
                    'parent-title-icon-is-mini': _vm.isMini,
                    'ml-4': _vm.isMini,
                  }},[_c('img',{attrs:{"src":page.icon,"height":"20px","alt":((page.displayName) + "-icon")}})]),_c('v-list-item-content',{class:{ 'hide-title-nav': _vm.isMini }},[_c('v-list-item-title',[_vm._v(_vm._s(page.displayName))])],1)]},proxy:true}],null,true)},_vm._l((page.children),function(child,index){return _c('v-list-item',{key:index,staticClass:"sidebar-menu-title",class:{
                  'selected-sidebar-menu-title': _vm.pageTitle === child.routeName,
                  'pl-6': !_vm.isMini,
                },attrs:{"data-cy":("navbar-" + (child.routeName.replace(' ', '-'))),"link":""},on:{"click":function () {
                    _vm.changePage(child);
                  }}},[(!_vm.isMini)?_c('div',[(child.notification === 'pendingLeaveRequestCount')?_c('div',{staticClass:"alert-circle mr-2",class:{ 'alert-circle-hidden': !_vm.pendingLeaveRequestCount }},[(child.notification === 'pendingLeaveRequestCount')?_c('span',[_vm._v(" "+_vm._s(_vm.pendingLeaveRequestCount)+" ")]):_vm._e()]):(child.notification === 'projectNotificationCount')?_c('div',{staticClass:"alert-circle mr-2",class:{ 'alert-circle-hidden': !_vm.projectNotificationCount }},[(child.notification === 'projectNotificationCount')?_c('span',[_vm._v(" "+_vm._s(_vm.projectNotificationCount)+" ")]):_vm._e()]):_c('div',{staticClass:"alert-circle mr-2 alert-circle-hidden"})]):_vm._e(),_c('v-list-item-action',{staticClass:"mr-2 my-2"},[_c('img',{attrs:{"src":child.icon,"height":"20px","alt":((page.displayName) + "-icon")}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(child.displayName))])],1)],1)}),1)]:[_c('v-list-item',{staticClass:"sidebar-menu-title",class:{
                'selected-sidebar-menu-title': _vm.pageTitle === page.routeName,
              },attrs:{"data-cy":("navbar-" + (page.routeName.replace(' ', '-'))),"link":""},on:{"click":function () {
                  _vm.changePage(page);
                }}},[_c('v-list-item-action',{staticClass:"mr-2"},[_c('img',{attrs:{"src":page.icon,"height":"20px","alt":((page.displayName) + "-icon")}})]),_c('v-list-item-content',{class:{ 'hide-title-nav': _vm.isMini }},[_c('v-list-item-title',[_vm._v(_vm._s(page.displayName))])],1)],1)]],2):_vm._e()])}),_c('div',{staticStyle:{"height":"50px","width":"20px"}})],2)],1),_c('v-app-bar',{attrs:{"app":"","color":"white","height":"75px","elevation":"1"}},[_c('img',{staticClass:"d-xl-none d-lg-none d-md-none mb-2",attrs:{"src":require('@/assets/images/sidebar-icon/sidebar-icon-menu-dark.svg'),"height":"20px","alt":"sidebar-menu-logo"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('v-toolbar-title',{staticClass:"font-extra-bold ml-8"},[_vm._v(_vm._s(_vm.pageTitle))]),_c('v-spacer'),_c('v-icon',{staticClass:"user-icon pr-2 d-none d-md-inline-block",attrs:{"dark":"","atl":"user-icon"}},[_vm._v("mdi-account-circle-outline")]),_c('v-menu',{attrs:{"offset-y":"","rounded":"xl"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"pt-1",attrs:{"data-cy":"logout-dropdown"}},'div',attrs,false),on),[_c('div',{staticClass:"d-none d-md-inline-block"},[_c('span',{staticClass:"text-capitalize mt-2"},[_vm._v(_vm._s(_vm.userDisplayName)+" | "+_vm._s(_vm.role.name))]),_c('v-icon',[_vm._v("mdi-chevron-down")])],1),_c('v-icon',{staticClass:"user-icon pr-4 d-md-none d-sm-inline-block",attrs:{"dark":""}},[_vm._v("mdi-account-circle-outline")])],1)]}}])},[_c('v-list',[_c('v-list-item',{staticClass:"d-md-none d-sm-flex"},[_c('span',[_c('strong',[_vm._v(_vm._s(_vm.userDisplayName)+" |")]),_vm._v(" "+_vm._s(_vm.role.name))])]),_c('v-divider',{staticClass:"d-md-none d-sm-inline-block divider"}),_c('v-list-item',{staticClass:"text-center",attrs:{"data-cy":"logout"},on:{"click":_vm.logout}},[_c('v-icon',{staticClass:"logout-icon"},[_vm._v("mdi-logout")]),_c('v-list-item-title',[_vm._v("Sign out")])],1)],1)],1)],1),_c('v-main',{staticClass:"main-content my-md-4 mx-md-8 ma-6"},[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }