








































































































































































import { AuthModule } from "@/store/modules/auth";
import { Component, Vue } from "vue-property-decorator";
import { Page } from "@/types/navbar.type";
import { UserModule } from "@/store/modules/user-module";
import { RoleInterface } from "@/types/user.type";
import { checkPermission } from "@/utils/permission";
import GitVersion from "@/components/git-version/GitVersion.vue";
import { ProjectModule } from "@/store/modules/project-module";
import { NotificationModule } from "@/store/modules/notification-module";

@Component({
  name: "Layout",
  components: {
    GitVersion,
  },
})
export default class extends Vue {
  get pageTitle(): string {
    return this.$route.name as string;
  }

  get isMini(): boolean {
    return !this.drawer;
  }

  set isMini(newValue: boolean) {
    this.drawer = newValue;
  }

  get isPermanent(): boolean {
    return this.$vuetify.breakpoint.mdAndUp;
  }
  private userDisplayName = "";
  private pageName = "";
  private role = {} as RoleInterface;
  private drawer = false;

  private pages: Page[] = [
    {
      displayName: "Timesheet",
      routeName: "Timesheet",
      icon: require("@/assets/images/sidebar-icon/sidebar-icon-timesheet.svg"),
      viewPermission: false,
      children: null,
    },
    {
      displayName: "Leave requests",
      routeName: "",
      icon: require("@/assets/icons/leave-requests.svg"),
      viewPermission: false,
      children: [
        {
          displayName: "Requests",
          routeName: "Leave Request",
          icon: require("@/assets/icons/requests.svg"),
          viewPermission: false,
        },
        {
          displayName: "Calendar Detail",
          routeName: "Leave Request Calendar",
          icon: require("@/assets/icons/leave-requests.svg"),
          viewPermission: false,
        },
      ],
    },
    {
      displayName: "Reports",
      routeName: "",
      icon: require("@/assets/images/sidebar-icon/sidebar-icon-report.svg"),
      viewPermission: false,
      children: [
        {
          displayName: "Dashboard",
          routeName: "Dashboard",
          icon: require("@/assets/images/sidebar-icon/sidebar-icon-dashboard.svg"),
          viewPermission: false,
        },
        {
          displayName: "Daily Report",
          routeName: "Reports",
          icon: require("@/assets/images/sidebar-icon/sidebar-icon-daily-report.svg"),
          viewPermission: false,
        },
      ],
    },
    {
      displayName: "Project",
      routeName: "",
      icon: require("@/assets/images/sidebar-icon/sidebar-icon-project.svg"),
      viewPermission: false,
      children: [
        {
          displayName: "Project",
          routeName: "Project List",
          icon: require("@/assets/images/sidebar-icon/sidebar-icon-subproject.svg"),
          notification: "projectNotificationCount",
          viewPermission: false,
        },
        {
          displayName: "Download Report",
          routeName: "Download Report",
          icon: require("@/assets/images/sidebar-icon/sidebar-icon-download-report.svg"),
          viewPermission: false,
        },
      ],
    },
    {
      displayName: "Admin",
      routeName: "",
      icon: require("@/assets/images/sidebar-icon/sidebar-icon-admin.svg"),
      viewPermission: false,
      children: [
        {
          displayName: "Client",
          routeName: "Clients",
          icon: require("@/assets/images/sidebar-icon/sidebar-icon-client.svg"),
          viewPermission: false,
        },
        {
          displayName: "Users",
          routeName: "Users",
          icon: require("@/assets/images/sidebar-icon/sidebar-icon-user.svg"),
          viewPermission: false,
        },
        {
          displayName: "Day Off",
          routeName: "Day Off",
          icon: require("@/assets/images/sidebar-icon/sidebar-icon-dayoff.svg"),
          viewPermission: false,
        },
        {
          displayName: "Leave Requests",
          routeName: "Leave Request Admin",
          icon: require("@/assets/icons/requests.svg"),
          notification: "pendingLeaveRequestCount",
          viewPermission: false,
        },
        {
          displayName: "Admin Setting",
          routeName: "Admin Setting",
          icon: require("@/assets/images/sidebar-icon/sidebar-icon-admin-setting.svg"),
          viewPermission: false,
        },
      ],
    },
  ];

  public changePage(page: Page) {
    this.pageName = page.displayName;
    if (this.$route.name === page.routeName) {
      return;
    }
    this.$router.push({ name: page.routeName });
  }

  public logout() {
    AuthModule.logout();
    this.$router.push({ name: "Login" });
  }

  get pendingLeaveRequestCount() {
    return NotificationModule.leaveRequestCount;
  }

  get projectNotificationCount() {
    return NotificationModule.projectNotificationCount;
  }

  public async mounted() {
    this.userDisplayName = UserModule.userProfile.firstName;
    this.pageName = this.$route.name as string;
    this.role = UserModule.userProfile.role;

    for (const page of this.pages) {
      if (!page.children) {
        page.viewPermission = checkPermission(UserModule.userProfile, page.routeName);
      } else {
        page.viewPermission = page.children.some((child: any) => {
          return checkPermission(UserModule.userProfile, child.routeName);
        });

        page.children.map((child: any) => {
          child.viewPermission = checkPermission(UserModule.userProfile, child.routeName);
        });
      }
    }
  }
}
